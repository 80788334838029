(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cancellations.canceledMatches.controller:CanceledMatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.cancellations.canceledMatches')
    .controller('CanceledMatchesCtrl', CanceledMatchesCtrl);

  function CanceledMatchesCtrl($state, $mdToast, $q, $filter, Matches, LastCancellation, Entitats, AclService, currentSeason, townHalls, cancellationTypes) {
    var vm = this;
    vm.ctrlName = 'CanceledMatchesCtrl';

    vm.cancellationTypes = cancellationTypes;

    vm.showList = false;
    vm.matches = [];
    vm.today = moment().toDate();
    vm.endDate = vm.today;
    vm.initDate = moment(currentSeason.iniDate).toDate();
    vm.townhalls = _.cloneDeep(townHalls);

    vm.currentPage = 1;
    vm.matchesPerPage = 25;

    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    if (vm.townhalls.length === 1) {
      vm.selectedTownHall = vm.townhalls[0];
      applyFilters();
    }


    vm.applyFilters = applyFilters;
    vm.showPostponeButton = showPostponeButton;
    vm.showEditButton = showEditButton;
    vm.showResolveSwitch = showResolveSwitch;
    vm.updateMatchState = updateMatchState;
    vm.editCancellation = editCancellation;
    vm.postponeCancellation = postponeCancellation;

    function showEditButton(match) {
      return !match.lastCancellation.resolved;
    }

    function postponeCancellation(match) {
      $state.go('home.cancellations.canceledMatches.postponeCanceledMatches', {matchId: match.id})
    }

    function editCancellation(match) {
      $state.go('home.cancellations.edit', {matchId: match.id})
    }

    function showPostponeButton(match) {
      return !match.lastCancellation.type.committee && !match.lastCancellation.resolved;
    }

    function showResolveSwitch(match) {
      return match.lastCancellation.type.committee && AclService.isAdmin();
    }

    function updateMatchState(match) {
      var postObject = {};
      postObject.resolved = match.lastCancellation.resolved;

      LastCancellation.update({id: match.id}, postObject, function () {
        if (match.lastCancellation.resolved) {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('CANCELLATIONS.RESOLVED_EDITION'))
              .position('bottom left')
              .hideDelay(3000)
          );
        } else {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('CANCELLATIONS.UNRESOLVED_EDITION'))
              .position('bottom left')
              .hideDelay(3000)
          );
        }
      }, function (error) {
        match.lastCancellation.resolved = !match.lastCancellation.resolved;
        var message;
        if (error.status === 400) {
          message = 'CANCELLATIONS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

    function applyFilters() {
      vm.isFiltered = true;
      query(vm.selectedTownHall.id, vm.initDate, vm.endDate, vm.currentPage, vm.sort);
    }


    function queryMyEntities() {
      Entitats.query(function (data) {
        vm.myEntities = data;
        if (vm.matches.length !== 0) {
          vm.showList = true;
        }
      })
    }

    function query(townHallId, initDate, endDate, page, sort) {
      var deferredMatches = $q.defer();
      vm.promiseMatches = deferredMatches.promise;
      var parameters = {
        townHall: townHallId,
        initDate: moment(initDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        page: page,
        size: vm.matchesPerPage,
        cancellation: true
      };
      //Optional parameters
      if (angular.isDefined(sort)) {
        parameters.sort = sort;
      }
      Matches.get(parameters, function (data) {
        _.remove(vm.matches);
        angular.forEach(data.matches, function (match) {
          vm.matches.push(parseMatch(data, match));
        });
        queryMyEntities();
        vm.maxMatches = data.page.count;
        deferredMatches.resolve();
      }, function () {
        deferredMatches.resolve();
      });
    }

    function parseMatch(data, match) {
      var facility = findItemById(data.facilities, match.facilityId);
      var localTeam = findItemById(data.teams, match.localTeamId);
      var visitorTeam = findItemById(data.teams, match.visitorTeamId);
      return {
        id: match.id,
        localTeam: angular.isDefined(localTeam) ? localTeam : {name: $filter('translate')('CALENDAR.REST')},
        visitorTeam: angular.isDefined(visitorTeam) ? visitorTeam : {name: $filter('translate')('CALENDAR.REST')},
        date: moment(match.date).format('L'),
        time: match.time === null ? undefined : match.time,
        facility: angular.isDefined(facility) ? facility : undefined,
        championship: findItemById(data.championships, match.championshipId).name,
        lastCancellation: parseCancellation(match)
      };
    }

    function parseCancellation(match) {
      return {
        id: match.lastCancellation.id,
        resolved: match.lastCancellation.resolved,
        typeId: match.lastCancellation.typeId,
        type: findItemById(vm.cancellationTypes, match.lastCancellation.typeId),
        details: match.lastCancellation.details
      }
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
